<template>
    <div class="panel-container">
        <router-view />
    </div>
</template>

<script>
    export default {
        name:'Panel',
    }
</script>

<style lang="scss" scoped>

</style>